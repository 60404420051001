<div class="menu-container">
  <div class="logo">
    <img src="assets/logosetinstone.webp" alt="Logo">
  </div>
  <nav class="menu-items" >
    <a routerLink="/" class="menu-item">
      ✨ Secure Your SmartContract
    </a>
    <a *ngIf="isLoggedIn && !isMobile" (click)="seeContent()" class="menu-item">
      📊 Dashboard
    </a>
    <a *ngIf="!isMobile && isLoggedIn || !isLoggedIn" (click)="seePrices()" class="menu-item">💳 {{ isLoggedIn ? 'Subscription' : 'Prices' }}</a>
    <a *ngIf="!isMobile && isLoggedIn " (click)="seeApiKeys()" class="menu-item">🔑 Api-Keys</a>

    <a *ngIf="!isLoggedIn && menuTabSwitch == 'signUp' && !isMobile" (click)="signIn()" class="menu-item">SignIn</a>
    <a *ngIf="!isLoggedIn && menuTabSwitch != 'signUp' && !isMobile" (click)="signUp()" class="menu-item">SignUp</a>
    
   <a *ngIf="isLoggedIn && !isMobile " (click)="signOut()" class="menu-item">SignOut</a>
  </nav>
<!--
  <div class="grid-container" *ngIf="isLoggedIn && !firstLogin">
    <div class="grid-item">{{ userEmail }}</div>
    <div class="grid-item grid">Current Plan: <br><strong> {{ status }}<small class="curBilCycle" *ngIf="status !== 'Canceled'">({{ endDate === nextUpdate ? 'Monthly' : 'Annually'}})</small></strong></div>
    <div class="grid-item">Start Date: {{ creationDate }}</div>
    <div (click)="signOut()" class="grid-item signOut-button"><a (click)="signOut()" class="menu-item ">Sign Out</a></div>
    <div class="grid-item grid">Remaining Uses: <div><strong>{{ remainingUses }}</strong><span class="small-text"> until {{ nextUpdate }}</span></div> </div>
    <div class="grid-item">End Date: {{ endDate }}</div>
</div>
-->

</div>

  <div class="grid-container" *ngIf="isLoggedIn && !firstLogin">
    <div class="grid-item">{{ userEmail }}</div>
    <div class="grid-item grid">Current Plan: <br><strong> {{ status }}<small class="curBilCycle" *ngIf="status !== 'Canceled'">({{ endDate === nextUpdate ? 'Monthly' : 'Annually'}})</small></strong></div>
    <!--<div class="grid-item">Start Date: {{ creationDate }}</div>-->
    <!-- <div (click)="signOut()" class="grid-item signOut-button"><a (click)="signOut()" class="menu-item ">Sign Out</a></div>-->
    <div class="grid-item grid">Remaining Uses: <div><strong>{{ remainingUses }}</strong><span class="small-text"> until {{ nextUpdate }}</span></div> </div>
    <div class="grid-item">Start Date: {{ creationDate }}</div>
    <div class="grid-item">End Date: {{ endDate }}</div>
</div>

<div class="container" *ngIf="!isMobile; else mobileMessage">  
  <app-dashboard-login *ngIf="!isLoggedIn && menuTabSwitch == 'login'"></app-dashboard-login>
  <app-dashboard-signup *ngIf="!isLoggedIn && menuTabSwitch == 'signUp'" (setFirstLogin)="setFirstLogin()"></app-dashboard-signup>
  <app-dashboard-content *ngIf="isLoggedIn && !firstLogin && menuTabSwitch != 'prices'  && menuTabSwitch != 'api-keys' || menuTabSwitch == 'content'" ></app-dashboard-content>
  <app-api-creation *ngIf="isLoggedIn && menuTabSwitch == 'api-keys'" ></app-api-creation>
  <app-dashboard-prices *ngIf="menuTabSwitch == 'prices'" (signUp)="signUp()" (getData)="getUserData()" [isLoggedIn]="isLoggedIn" [firstLogin]="firstLogin" [status]="status"></app-dashboard-prices>
</div>



<ng-template #mobileMessage>
  <p class="mobile-message">Sorry, this feature is only available on the desktop version.</p>
  <a routerLink="/" class="back-button">Back To Homepage</a>
</ng-template>
