<div class="main-container" *ngIf="!isMobile">
  
  <div class="container">
    <div class="menu-container">
      <div class="logo">
        <img src="assets/logosetinstone.webp" alt="Logo">
      </div>
      <nav class="menu-items" >
        <a href="{{ menu_URL }}/dashboard" target="_blank" class="menu-item">✨ Secure Your SmartContract</a>
    
        <a href="{{ menu_URL }}/dashboard" target="_blank" class="menu-item">📊 Sign In</a>
      </nav>
      
    </div>
    <h2 class="card-title" style="margin-bottom: 1.75rem">Push your code, Click the button, Get your security report</h2>
    <p class="card-text">🎯 Goal: Improve The Fundamental Security of Your SmartContracts.</p>
    <p class="card-text">
      ⚙️ Task: Offers You A Pre-audit Report Of SmartContracts, In A Few Minutes, Whenever You Want.
    </p>
    <p class="card-text">
      📝 Please respect the
      <a href="{{ notionRulesLink }}" target="_blank"><strong>usage rules</strong></a>
      to successfully conduct the analyses of your programs.
    </p>
    <app-github-logout-button [isAuthenticated]="isAuthenticated"></app-github-logout-button>

    <mat-card class="formDesktop">
      <mat-stepper orientation="horizontal" #stepper class="stepper">
        <mat-step>
          <h2 style="color: white">Share your code with Github</h2>
          <form class="firstFormGroup">
            <ng-template matStepLabel><label>Code Base</label></ng-template>
            <div class="row-div">
              <div *ngIf="!isAuthenticated" style="width: 60%">
                <h4 class="tool-title">Public Github repository</h4>
                <mat-form-field style="width: 100%" color="accent">
                  <mat-label class="txt">Public Github repository URL</mat-label>
                  <input
                    maxlength="100"
                    matInput
                    [(ngModel)]="formData.github"
                    [ngModelOptions]="{ standalone: true }"
                    #githubInput="ngModel"
                    name="github"
                    [required]="!isAuthenticated"
                    [disabled]="selectedFile !== undefined"
                  />
                  <mat-error *ngIf="githubInput.invalid" class="txt">Enter your Github repository URL.</mat-error>
                  <mat-hint *ngIf="githubInput.value" align="end" class="txt"
                    >{{ githubInput.value.length }}/100</mat-hint
                  >
                </mat-form-field>
              </div>
              <app-github-login-button
                *ngIf="!isAuthenticated"
                style="width: 40%"
                [isAuthenticated]="isAuthenticated"
              ></app-github-login-button>

              <div *ngIf="isAuthenticated" class="row-reverse-div" style="width: 100%">
                <app-github style="width: 100%" [formData]="formData" class="row-reverse-div"></app-github>
              </div>
            </div>
            <h2 style="color: white">Or directly upload your code</h2>
            <div
              class="file-drop-zone"
              (drop)="onFileDropped($event)"
              (dragover)="onFileOver($event)"
              (click)="fileInput.click()"
              [ngClass]="{ disabled: isAuthenticated && server.isRepoDataSet }"
              [ngClass]="{ disabled: formData.github !== '' }"
            >
              <p class="drop-text" *ngIf="!selectedFile">
                Drop .zip, .tar or .tar.gz files here or click to upload (100 MB)
              </p>
              <p class="drop-text selected-file-info" style="margin-left: 27.5%" *ngIf="selectedFile">
                <span>Selected file: {{ selectedFile.name }} ({{ formatBytes(selectedFile.size) }})</span>
                <button mat-icon-button (click)="removeSelectedFile($event)">
                  <mat-icon>clear</mat-icon>
                </button>
              </p>
              <p class="error-msg" *ngIf="fileError">{{ fileError }}</p>
              <input
                #fileInput
                type="file"
                (change)="onFileSelected($event)"
                style="display: none"
                accept=".zip, .tar, .tar.gz"
                [disabled]="isAuthenticated && server.isRepoDataSet"
                [disabled]="formData.github !== ''"
              />
            </div>

            <div class="personal-informations-button">
              <button
                mat-raised-button
                matStepperNext
                type="submit"
                class="first-form-button"
                (click)="checkFirstForm()"
              >
                <p class="txt">Next</p>
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step>
          <form class="firstFormGroup">
            <ng-template matStepLabel><label class="txt">Project Informations</label></ng-template>
            <div class="codebase-inputs">
              <div class="url-title-and-input2">
                <h4 class="tool-title">Goal</h4>
                <mat-form-field style="width: 90%" color="accent">
                  <mat-label for="goal" class="txt">Select</mat-label>
                  <mat-select
                    class="txt"
                    id="goal"
                    [(ngModel)]="formData.goal"
                    [ngModelOptions]="{ standalone: true }"
                    #toolsInput="ngModel"
                    #reasonInput="ngModel"
                    name="tools"
                    required
                  >
                    <mat-option value="Safer" class="txt">Develop safer smartcontracts</mat-option>
                    <mat-option value="Audit" class="txt">Audit your client smartcontracts</mat-option>
                    <mat-option value="Other" class="txt">Others activities</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="tools-title-and-input">
                <h4 class="tool-title">Development tool (Optional)</h4>
                <mat-form-field style="width: 85%" color="accent">
                  <mat-label for="tools-select" class="txt">Select</mat-label>
                  <mat-select
                    class="txt"
                    id="tools-select"
                    [(ngModel)]="formData.tools"
                    [ngModelOptions]="{ standalone: true }"
                    #toolsInput="ngModel"
                    name="tools"
                  >
                    <!-- <mat-option value="Brownie" class="txt">Brownie</mat-option> -->
                    <mat-option value="" class="txt">No IDE</mat-option>
                    <mat-option value="Foundry" class="txt">Foundry</mat-option>
                    <mat-option value="Hardhat" class="txt">Hardhat</mat-option>
                    <mat-option value="Truffle" class="txt">Truffle</mat-option>
                    <!-- <mat-option value="Waffle" class="txt">Waffle</mat-option> -->
                  </mat-select>
                  <mat-error *ngIf="toolsInput.invalid" class="txt">Select your development tool.</mat-error>
                </mat-form-field>
              </div>
              
              <div class="url-title-and-input">
                <h4 class="tool-title">Website (Optional)</h4>
                <mat-form-field style="width: 100%" color="accent">
                  <mat-label class="txt">Website</mat-label>
                  <input
                    maxlength="100"
                    matInput
                    [(ngModel)]="formData.website"
                    [ngModelOptions]="{ standalone: true }"
                    #websiteInput="ngModel"
                    name="website"
                  />
                  <!-- <mat-error *ngIf="websiteInput.invalid" class="txt">Enter your website URL.</mat-error> -->
                  <mat-hint *ngIf="websiteInput.value" align="end" class="txt"
                    >{{ websiteInput.value.length }}/100</mat-hint
                  >
                </mat-form-field>
              </div>
              
            </div>
            <div class="desc-inputs">
              <div class="desc-input">
                <h4 class="tool-title">Project description (Optional)</h4>
                <mat-form-field style="width: 100%; height: 10rem" color="accent">
                  <mat-label class="txt">Project description</mat-label>
                  <textarea
                    class="txt"
                    maxlength="1000"
                    matInput
                    [(ngModel)]="formData.description"
                    [ngModelOptions]="{ standalone: true }"
                    #descInput="ngModel"
                    name="description"
                    style="resize: none; height: 6.45rem"
                  ></textarea>
                  <!-- <mat-error *ngIf="descInput.invalid" class="txt">Enter your project description.</mat-error> -->
                  <mat-hint *ngIf="descInput.value" align="end" class="txt">{{ descInput.value.length }}/1000</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="project-informations-button">
              <button mat-raised-button matStepperPrevious class="first-form-button txt">Back</button>
              <button
                mat-raised-button
                matStepperNext
                class="txt first-form-button"
                id="submit-button"
                type="submit"
                style="margin-left: 0.45rem"
                (click)="checkSecondForm()"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <form class="firstFormGroup">
            <ng-template matStepLabel><label class="txt">Personnal Informations</label></ng-template>
            <div class="firstanme-and-lastname-inputs" style="margin-top: 1.15rem">
              <div class="firstname-title-and-input">
                <h4 class="firstname-title">Firstname</h4>
                <mat-form-field class="firstname green" color="accent">
                  <mat-label class="txt">Firstname</mat-label>
                  <input
                    class="txt"
                    matInput
                    maxlength="20"
                    [(ngModel)]="formData.firstname"
                    [ngModelOptions]="{ standalone: true }"
                    #prenomInput="ngModel"
                    name="prenom"
                    required
                  />
                  <!-- <mat-error *ngIf="prenomInput.invalid" class="txt">Enter your firstname.</mat-error> -->
                  <mat-hint *ngIf="prenomInput.value" align="end" class="txt"
                    >{{ prenomInput.value.length }}/20</mat-hint
                  >
                </mat-form-field>
              </div>
              <div class="lastname-title-and-input">
                <h4 class="lastname-title">Lastname</h4>
                <mat-form-field class="lastname green" color="accent">
                  <mat-label class="txt">Lastname</mat-label>
                  <input
                    matInput
                    maxlength="20"
                    [(ngModel)]="formData.lastname"
                    [ngModelOptions]="{ standalone: true }"
                    #nomInput="ngModel"
                    name="nom"
                    required
                  />
                  <!-- <mat-error *ngIf="nomInput.invalid" class="txt">Enter your lastname.</mat-error> -->
                  <mat-hint *ngIf="nomInput.value" align="end" class="txt">{{ nomInput.value.length }}/20</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="firstanme-and-lastname-inputs" style="margin-top: 2rem">
              <div class="firstname-title-and-input2">
                <h4 class="firstname-title">Email</h4>
                <mat-form-field class="firstname green" color="accent">
                  <mat-label class="txt">Email</mat-label>
                  <input
                    maxlength="40"
                    inputmode="email"
                    type="email"
                    matInput
                    [(ngModel)]="formData.email"
                    [ngModelOptions]="{ standalone: true }"
                    #emailInput="ngModel"
                    name="email"
                    required
                  />
                </mat-form-field>
                <!-- <mat-error *ngIf="emailInput.invalid">Enter your email.</mat-error> -->
                <mat-hint *ngIf="emailInput.value" align="end">{{ emailInput.value.length }}/40</mat-hint>
              </div>
              <div class="lastname-title-and-input2">
                <h4 class="lastname-title">Telegram</h4>
                <mat-form-field class="firstname green" color="accent">
                  <mat-label class="txt">Telegram</mat-label>
                  <!-- <span matPrefix class="txt at">@</span> -->
                  <input
                    maxlength="50"
                    matInput
                    [(ngModel)]="formData.telegram"
                    [ngModelOptions]="{ standalone: true }"
                    #telegramInput="ngModel"
                    name="telegram"
                    required
                  />
                </mat-form-field>
                <mat-hint *ngIf="telegramInput.value" align="end">{{ emailInput.value.length }}/40</mat-hint>
              </div>
            </div>
            <!-- <div class="firstname-title-and-input3">
              
            </div> -->

            <div class="project-informations-button" style="text-align: center; margin-top: 2.5rem">
              <button mat-raised-button matStepperPrevious class="txt first-form-button">Back</button>
              <button
                mat-raised-button
                matStepperNext
                type="submit"
                style="margin-left: 0.5rem"
                class="txt first-form-button"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel><label>Send</label></ng-template>
          <form class="firstFormGroup">
            <div class="setup-complete">
              <strong>All Set!</strong>
              <p>Your setup is complete. You can now generate your report.</p>
            </div>
            <div class="checkbox-container">
              <mat-checkbox
                class="terms-checkbox"
                style="margin-bottom: -5px"
                [(ngModel)]="formData.termsAccepted"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                By checking this box, I declare that I have read the
                <a href="{{ rulesLink }}" target="_blank"><strong>general conditions</strong></a>
                of use of the website and accept them.
              </mat-checkbox>
              <mat-checkbox
                class="terms-checkbox"
                [(ngModel)]="formData.infoAgreement"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                By submitting this form, I agree that my information will be used exclusively within the framework of my
                request and the ethical and personalized commercial relationship that could result if I wish.
              </mat-checkbox>
            </div>
            <div class="project-informations-button" style="text-align: center; margin-top: 1.65rem">
              <button mat-raised-button matStepperPrevious class="txt first-form-button" [disabled]="isSendDisable">
                Back
              </button>
              <button
                mat-raised-button
                type="submit"
                (click)="submitForm()"
                [disabled]="isSendDisable"
                style="margin-left: 0.5rem"
                class="txt first-form-button"
              >
                Send
              </button>
            </div>
          </form>
          <div *ngIf="isLoading" class="overlay">
            <div class="loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </mat-card>

    <!--
    <h2 class="txt back">
      <mat-icon
        aria-hidden="false"
        aria-label="Back home icon"
        fontIcon="arrow_back"
        style="margin-bottom: -4px"
      ></mat-icon>
      <a href="https://lab.setinstone.io/">Back to the Home page</a>
    </h2>
    --> 
  </div>
</div>

<div *ngIf="isMobile">
  <img src="assets/logosetinstone.webp" alt="Logo" class="logo-mobile" />
  <h2 class="prepareTitle">Sec Assistant #01 : Pre-audit Your Solidity Code</h2>
  <p class="prepareTitle" style="margin-top: 0.85rem">
    🎯 Goal: Improve The Fundamental Security of Your SmartContracts.
  </p>
  <p class="prepareTitle">
    ⚙️ Task: Offers You A Pre-audit Report Of SmartContracts, In A Few Minutes, Whenever You Want.
  </p>
  <p class="prepareTitle">
    📝 Please respect the
    <a href="{{ notionRulesLink }}" target="_blank"><strong>usage rules</strong></a>
    to successfully conduct the analyses of your programs.
  </p>
  <app-github-logout-button [isAuthenticated]="isAuthenticated"></app-github-logout-button>

  <mat-card class="form">
    <mat-stepper orientation="vertical" #stepper class="stepper-mobile">
      <mat-step>
        <h2 style="color: white; font-size: 1.35rem">Share your code with Github</h2>
        <form class="firstFormGroup">
          <ng-template matStepLabel><label>Code Base</label></ng-template>
          <div class="column-div">
            <div *ngIf="!isAuthenticated" style="width: 100%">
              <h4 class="tool-title">Public Github repository</h4>
              <mat-form-field style="width: 100%" color="accent">
                <mat-label class="txt">Public Github repository URL</mat-label>
                <input
                  maxlength="100"
                  matInput
                  [(ngModel)]="formData.github"
                  [ngModelOptions]="{ standalone: true }"
                  #githubInput="ngModel"
                  name="github"
                  [required]="!isAuthenticated"
                  [disabled]="selectedFile !== undefined"
                />
                <mat-error *ngIf="githubInput.invalid" class="txt">Enter your Github repository URL.</mat-error>
                <mat-hint *ngIf="githubInput.value" align="end" class="txt"
                  >{{ githubInput.value.length }}/100</mat-hint
                >
              </mat-form-field>
            </div>
            <app-github-login-button
              *ngIf="!isAuthenticated"
              style="width: 90%"
              [isAuthenticated]="isAuthenticated"
            ></app-github-login-button>

            <div *ngIf="isAuthenticated" class="column-div" style="width: 100%">
              <app-github style="width: 100%" [formData]="formData" class="row-reverse-div"></app-github>
            </div>
          </div>
          <h2 style="color: white; font-size: 1.35rem; margin-top: 2.5rem">Or directly upload your code</h2>
          <div
            class="file-drop-zone"
            (drop)="onFileDropped($event)"
            (dragover)="onFileOver($event)"
            (click)="fileInput.click()"
            [ngClass]="{ disabled: isAuthenticated && server.isRepoDataSet }"
            [ngClass]="{ disabled: formData.github !== '' }"
          >
            <p class="drop-text" *ngIf="!selectedFile">
              Drop .zip, .tar or .tar.gz files here or click to upload (100 MB)
            </p>
            <p class="drop-text selected-file-info" *ngIf="selectedFile">
              <span>Selected file: {{ selectedFile.name }} ({{ formatBytes(selectedFile.size) }})</span>
              <button mat-icon-button (click)="removeSelectedFile($event)">
                <mat-icon>clear</mat-icon>
              </button>
            </p>
            <p class="error-msg" *ngIf="fileError">{{ fileError }}</p>
            <input
              #fileInput
              type="file"
              (change)="onFileSelected($event)"
              style="display: none"
              accept=".zip"
              [disabled]="isAuthenticated && server.isRepoDataSet"
              [disabled]="formData.github !== ''"
            />
          </div>

          <div class="personal-informations-button">
            <button mat-raised-button matStepperNext type="submit" class="first-form-button" (click)="checkFirstForm()">
              <p class="txt">Next</p>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step errorMessage="Personnal Informations Required">
        <form class="firstFormGroup">
          <ng-template matStepLabel>Project Informations</ng-template>
          <div class="lastname-title-and-input2-mobile">
            <h4 class="lastname-title">Goal</h4>
            <mat-form-field class="email green" color="accent">
              <mat-label for="goals2" class="txt">Select</mat-label>
              <mat-select
                id="goals2"
                class="txt"
                [(ngModel)]="formData.goal"
                [ngModelOptions]="{ standalone: true }"
                #toolsInput="ngModel"
                #reasonInput="ngModel"
                name="tools"
                required
              >
                <mat-option value="Safer" class="txt">Develop safer smartcontracts</mat-option>
                <mat-option value="Audit" class="txt">Audit your client smartcontracts</mat-option>
                <mat-option value="Other" class="txt">Others activities</mat-option>
              </mat-select>
              <mat-error *ngIf="reasonInput.invalid" class="txt"
                >Enter how you will use your pre-audit report.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="outils-input-mobile">
            <div class="email-title-and-input2">
              <h4 class="email-title">Development tool (Optional)</h4>
              <mat-form-field style="width: 100%" color="accent">
                <mat-label for="tools-sel">Select</mat-label>
                <mat-select
                  id="tools-sel"
                  [(ngModel)]="formData.tools"
                  [ngModelOptions]="{ standalone: true }"
                  #toolsInput="ngModel"
                  name="tools"
                >
                  <mat-option value="" class="txt">No IDE</mat-option>
                  <!-- <mat-option value="Brownie" class="txt">Brownie</mat-option> -->
                  <mat-option value="Foundry" class="txt">Foundry</mat-option>
                  <mat-option value="Hardhat" class="txt">Hardhat</mat-option>
                  <mat-option value="Truffle" class="txt">Truffle</mat-option>
                  <!-- <mat-option value="Waffle" class="txt">Waffle</mat-option> -->
                </mat-select>
                <!-- <mat-error *ngIf="toolsInput.invalid">Select a development tool.</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          
          <div class="outils-input-mobile">
            <div class="email-title-and-input2">
              <h4 class="email-title">Website (Optional)</h4>
              <mat-form-field style="width: 100%" color="accent">
                <mat-label>Website</mat-label>
                <input
                  maxlength="100"
                  matInput
                  [(ngModel)]="formData.website"
                  [ngModelOptions]="{ standalone: true }"
                  #websiteInput="ngModel"
                  name="website"
                />
                <!-- <mat-error *ngIf="websiteInput.invalid">Enter your website URL.</mat-error> -->
                <mat-hint *ngIf="websiteInput.value" align="end">{{ websiteInput.value.length }}/100</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="desc-inputs">
            <div class="desc-input" style="height: 8rem">
              <h4 class="email-title">Project Description (Optional)</h4>
              <mat-form-field style="width: 100%; height: 100%; margin-top: 1rem" color="accent">
                <mat-label>Project Description</mat-label>
                <textarea
                  maxlength="1000"
                  matInput
                  [(ngModel)]="formData.description"
                  [ngModelOptions]="{ standalone: true }"
                  #descInput="ngModel"
                  name="description"
                  style="resize: none; height: 3rem"
                ></textarea>
                <!-- <mat-error *ngIf="descInput.invalid">Enter your project description.</mat-error> -->
                <mat-hint *ngIf="descInput.value" align="end">{{ descInput.value.length }}/1000</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="project-informations-button" style="text-align: center; margin-top: 6.5rem">
            <button mat-raised-button matStepperPrevious class="first-form-button">Back</button>
            <button
              mat-raised-button
              matStepperNext
              id="submit-button"
              type="submit"
              style="margin-left: 0.45rem"
              class="first-form-button"
              (click)="checkSecondForm()"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <form class="firstFormGroup">
          <ng-template matStepLabel>Personnal Informations</ng-template>

          <div class="firstanme-and-lastname-inputs">
            <div class="firstname-title-and-input">
              <h4 class="firstname-title">Firstname</h4>
              <mat-form-field class="firstname" color="accent">
                <mat-label>Firstname</mat-label>
                <input
                  matInput
                  maxlength="20"
                  [(ngModel)]="formData.firstname"
                  [ngModelOptions]="{ standalone: true }"
                  #prenomInput="ngModel"
                  name="prenom"
                  required
                />
                <!-- <mat-error *ngIf="prenomInput.invalid">Enter your name.</mat-error> -->
                <mat-hint *ngIf="prenomInput.value" align="end">{{ prenomInput.value.length }}/20</mat-hint>
              </mat-form-field>
            </div>
            <div class="lastname-title-and-input">
              <h4 class="lastname-title">Lastname</h4>
              <mat-form-field class="lastname" color="accent">
                <mat-label>Lastname</mat-label>
                <input
                  matInput
                  maxlength="20"
                  [(ngModel)]="formData.lastname"
                  [ngModelOptions]="{ standalone: true }"
                  #nomInput="ngModel"
                  name="nom"
                  required
                />
                <!-- <mat-error *ngIf="nomInput.invalid">Enter your lastname.</mat-error> -->
                <mat-hint *ngIf="nomInput.value" align="end">{{ nomInput.value.length }}/20</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="email-input">
            <div class="email-title-and-input-mobile">
              <h4 class="email-title">Email</h4>
              <mat-form-field class="email" color="accent">
                <mat-label>Email</mat-label>
                <input
                  maxlength="40"
                  matInput
                  [(ngModel)]="formData.email"
                  [ngModelOptions]="{ standalone: true }"
                  #emailInput="ngModel"
                  name="email"
                  required
                />
                <!-- <mat-error *ngIf="emailInput.invalid">Enter your email.</mat-error> -->
                <mat-hint *ngIf="emailInput.value" align="end">{{ emailInput.value.length }}/40</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="email-input2">
            <div class="email-title-and-input-mobile">
              <h4 class="email-title">Telegram</h4>
              <mat-form-field class="email" color="accent">
                <mat-label>Telegram</mat-label>
                <input
                  maxlength="50"
                  matInput
                  [(ngModel)]="formData.telegram"
                  [ngModelOptions]="{ standalone: true }"
                  #telegramInput="ngModel"
                  name="telegram"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          

          <div class="personal-informations-button">
            <button mat-raised-button matStepperPrevious class="first-form-button">Back</button>
            <button
              mat-raised-button
              matStepperNext
              type="submit"
              style="margin-left: 0.5rem"
              class="first-form-button"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel><label>All Set!</label></ng-template>
        <form class="firstFormGroup">
          <p>Your setup is complete. You can now generate your report.</p>
          <div class="checkbox-container-mobile">
            <mat-checkbox
              class="terms-checkbox"
              style="margin-bottom: 0.35rem"
              [(ngModel)]="formData.termsAccepted"
              [ngModelOptions]="{ standalone: true }"
              ngDefaultControl
            >
              By checking this box, I declare that I have read the
              <a href="{{ rulesLink }}" target="_blank"><strong>general conditions</strong></a>
              of use of the website and accept them.
            </mat-checkbox>
            <mat-checkbox
              class="terms-checkbox"
              [(ngModel)]="formData.infoAgreement"
              [ngModelOptions]="{ standalone: true }"
              ngDefaultControl
            >
              By submitting this form, I agree that my information will be used exclusively within the framework of my
              request and the ethical and personalized commercial relationship that could result if I wish.
            </mat-checkbox>
          </div>
          <div class="personal-informations-button" style="text-align: center; margin-top: 1.65rem">
            <button mat-raised-button matStepperPrevious class="txt first-form-button">Back</button>
            <button
              mat-raised-button
              type="submit"
              (click)="submitForm()"
              [disabled]="isSendDisable"
              style="margin-left: 0.5rem"
              class="first-form-button"
            >
              Send
            </button>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </mat-card>
  <footer>
    <!--
    <h4 style="margin-left: 8%; padding-bottom: 40px" class="txt">
      <mat-icon class="arrowBack" aria-hidden="false" aria-label="Back home icon" fontIcon="arrow_back"></mat-icon>
      <a href="https://lab.setinstone.io/">Back to the Home page</a>
    </h4>
    -->
  </footer>
</div>
